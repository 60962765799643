// @flow
import { combineReducers } from "redux";

import Auth from "./auth/reducers";
import Layout from "./layout/reducers";
import {
  businessEmailReducer,
  businessEmailVerifyReducer,
} from "./auth/businessEmail/reducers";
import {
  otpSendEmailReducer,
  subAccountantSendEmailVerifyReducer,
} from "./auth/otpSendEmail/reducers";
import {
  getCompanyNameReducer,
  registerUserReducer,
  companyCheckVerifyReducer,
  companyCreateReducer,
} from "./auth/verifyDetail/reducers";
import {
  resetPasswordEmailReducer,
  resetPasswordVerifyReducer,
} from "./auth/resetPassword/reducers";

import {
  organisationSettingReducer,
  getOrganisationSettingReducer,
  emailSendVerifyReducer,
  deleteEmailSendVerifyReducer,
  otpSendVerifyReducer,
} from "./accountPro/organisation/reducer";

import {
  updateProfileReducer,
  getProfileReducer,
  updatePasswordReducer,
} from "../redux/accountPro/profile/reducer";
import {
  getSubAccountantReducer,
  addSubAccountantReducer,
  updateSubAccountantReducer,
  deleteSubClientReducer,
} from "./subAccountant/reducer";
import {
  otpTimeStartReducer,
  signupVerifyBackBtnTimeStopReducer,
  mbleSccrnSideBrClseReducer,
} from "./ComponentDataSend/authComponentDataSend/reducers";

// new code
import {
  signupAccountReducer,
  getSkillReducer,
  getItaaReducer,
  getIndustoryReducer,
  getLanguagesReducer,
  getLocationReducer,
} from "./auth/accountant/reducers";
import {
  accountDetailReducer,
  accountSettingReducer,
  getAccountantProfileReducer,
  mobileNumberVerifyReducer,
  mobileNumberVerifyOtpSendReducer,
} from "./accountant/profile/acountDetail/reducers";
import {
  getSearchAccountantReducer,
  ignoreClienrRequestReducer,
  getMeetingStatusDetailsReducer,
} from "./accountant/searchAccountant/reducers";
import {
  getAnnouncementReducer,
  addAnnouncementReducer,
  updateAnnouncementReducer,
  deleteAnnouncementReducer,
  searchClientReducer,
  getAnnouncementsAccountantReducer,
} from "./accountant/announcements/reducer";
import {
  getAccountantClientReducer,
  getClientInvitationListReducer,
  acceptClientInvitationReducer,
  getDocusignTokenReducers,
  getContractListReducer,
  getClientContractContractReducer,
} from "./accountant/Dashboard/reducers";
import {
  getVatDetailsReducer,
  addVatDetailsReducer,
  addFinalVatDetailsReducer,
} from "./accountant/vatDetails/reducers";
import {
  addRatingReducer,
  getRatingReducer,
} from "./accountPro/AddRating/reducer";
import { getNotifectionReducer, getNotifectionQountReducer } from "./accountant/Notifection/reducer";

export default (combineReducers({
  Auth,
  Layout,
  businessEmailReducer,
  otpSendEmailReducer,
  subAccountantSendEmailVerifyReducer,
  getCompanyNameReducer,
  registerUserReducer,
  companyCheckVerifyReducer,
  resetPasswordEmailReducer,
  resetPasswordVerifyReducer,
  updateProfileReducer,
  getProfileReducer,
  updatePasswordReducer,
  getSubAccountantReducer,
  updateSubAccountantReducer,
  otpTimeStartReducer,
  signupVerifyBackBtnTimeStopReducer,
  companyCreateReducer,
  addSubAccountantReducer,
  signupAccountReducer,
  getSkillReducer,
  accountDetailReducer,
  mobileNumberVerifyReducer,
  mobileNumberVerifyOtpSendReducer,
  accountSettingReducer,
  getAccountantProfileReducer,
  organisationSettingReducer,
  getOrganisationSettingReducer,
  emailSendVerifyReducer,
  deleteEmailSendVerifyReducer,
  otpSendVerifyReducer,
  deleteSubClientReducer,
  getSearchAccountantReducer,
  mbleSccrnSideBrClseReducer,
  getAnnouncementReducer,
  addAnnouncementReducer,
  updateAnnouncementReducer,
  deleteAnnouncementReducer,
  getAccountantClientReducer,
  getVatDetailsReducer,
  addVatDetailsReducer,
  addFinalVatDetailsReducer,
  mobileNumberVerifyReducer,
  getAnnouncementsAccountantReducer,
  searchClientReducer,
  getItaaReducer,
  getIndustoryReducer,
  getClientInvitationListReducer,
  acceptClientInvitationReducer,
  getDocusignTokenReducers,
  getClientContractContractReducer,
  getContractListReducer,
  businessEmailVerifyReducer,
  getLanguagesReducer,
  addRatingReducer,
  getRatingReducer,
  ignoreClienrRequestReducer,
  getMeetingStatusDetailsReducer,
  getNotifectionReducer,
  getLocationReducer,
  getNotifectionQountReducer
}): any);
